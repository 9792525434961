import Vue from "vue";
import CookieConsent from "@/components/CookieConsent/CookieConsent.vue";




let app = new Vue({
    el: '#container',
    data: {


    },
    components: {
       CookieConsent: CookieConsent,
    },
    created() {
    },
    computed: {},
    methods: {

    }

});

window.$app = app;
